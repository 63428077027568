




































































import MemberApi from "@/api/member.api";
import { countdown, getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import moment from "moment";
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
@Component({
  components: {},
})
export default class GameCardReword extends Vue {
  @Prop() item!: any;
  @Prop({ default: true }) getRecycleStatus!: boolean;
  @Prop() receiveState!: boolean;
  @Prop() isRenew!: boolean;
  @Emit()
  rightNowGet() {}
  @Emit()
  toRecyle() {}
  stopCLick() {
    return;
  }
  @Emit()
  open(){}
  @Watch('receiveState')
  watchReceiveState(){
  }
  toDetail(id: any) {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
      query: { time: String(getNow()), id: `${id}` },
    });
  }

  DateData = "本期剩余 0天00:00:00";
  created() {
    console.log(this.getRecycleStatus);
    setInterval(() => {
      let time = Number(
        moment(moment().add(1, "M").format("YYYY-MM-01 00:00:00")).format("X")
      );
      const { hour, day, second, minute } = countdown(time);
      this.DateData = `本期剩余 ${day}天${hour}:${minute}:${second}`;
    }, 1000);
  }
  get Opacity() {
    if (this.getRecycleStatus) {
      return 0.2;
    }
    return 1;
  }
}
